var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  overflow: auto;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  overflow: auto;\n"])));
export var StyledLayoutText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: left;\n  margin-bottom: 1rem;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: left;\n  margin-bottom: 1rem;\n"])));
export var StyledLayoutTextQuestion = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  text-align: left;\n  margin-bottom: 1rem;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  text-align: left;\n  margin-bottom: 1rem;\n"])));
export var ContainerImage = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-radius: 16px;\n  padding-top: 24px;\n  max-width: 375px;\n  padding-bottom: 65px;\n"], ["\n  border-radius: 16px;\n  padding-top: 24px;\n  max-width: 375px;\n  padding-bottom: 65px;\n"])));
export var StyledImage = styled.object(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  max-height: 457px;\n  object-fit: fill;\n  margin: 0 auto;\n  transform: ", ";\n  display: block;\n"], ["\n  width: ", ";\n  height: ", ";\n  max-height: 457px;\n  object-fit: fill;\n  margin: 0 auto;\n  transform: ", ";\n  display: block;\n"])), function (_a) {
    var fallbackImage = _a.fallbackImage;
    return (fallbackImage ? "240px" : "210px");
}, function (_a) {
    var fallbackImage = _a.fallbackImage;
    return (fallbackImage ? "180px" : "158px");
}, function (_a) {
    var fallbackImage = _a.fallbackImage;
    return fallbackImage ? "translateY(9.2%)" : "";
});
export var EmptySpace = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var StyledLayoutTextContent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n"])));
export var StyledLayoutTextResult = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 0.5rem;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 0.5rem;\n"])));
export var StyledSkeletonResult = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
