var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Container, ContainerIconSkeleton } from "./styled";
import { ContainerIcon } from "./styled";
import { IconButton } from "@mui/material";
import { useCallback } from "react";
import { closeWebView, getLang } from "../../utils/helper";
import Skeleton from "@mui/material/Skeleton";
import CloseIcon from "../common/CloseIcon";
import { useApplication } from "../../App.context";
var HeaderSpace = function (_a) {
    var color = _a.color, LoadingColor = _a.LoadingColor;
    var appTheme = useApplication().appTheme;
    var isSkeleton = false;
    var lang = getLang().toLowerCase();
    var onClickIcon = useCallback(function () {
        closeWebView();
    }, []);
    return (_jsx(Container, __assign({ color: color }, { children: isSkeleton ? (_jsx(ContainerIconSkeleton, { children: _jsx(Skeleton, { variant: "circular", animation: "wave", width: 40, height: 40, sx: { bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color } }) })) : (_jsx(ContainerIcon, __assign({ onClick: onClickIcon, role: "button" }, { children: _jsx(IconButton, __assign({ size: "small", "aria-label": lang === "th" ? "ปิด" : lang === "en" ? "close" : "ปิด" }, { children: _jsx(CloseIcon, { LoadingColor: LoadingColor }) })) }))) })));
};
export default HeaderSpace;
