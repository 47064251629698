var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Container = styled("div")(function (_a) {
    var color = _a.color;
    return ({
        backgroundColor: color ? color : "rgba(0, 0, 0, 0)",
        position: "relative",
        boxSizing: "border-box",
        width: "100vw",
        zIndex: 10,
        display: "flex",
        height: "92px",
    });
});
export var ContainerContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  text-align: center;\n  width: 100%;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  text-align: center;\n  width: 100%;\n  justify-content: center;\n"])));
export var ContainerIcon = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-right: 18px;\n  padding-top: 5px;\n  position: absolute;\n  right: 0;\n  margin-top: 44px;\n\n  & .MuiIconButton-root {\n    width: 20px;\n    height: 20px;\n  }\n"], ["\n  padding-right: 18px;\n  padding-top: 5px;\n  position: absolute;\n  right: 0;\n  margin-top: 44px;\n\n  & .MuiIconButton-root {\n    width: 20px;\n    height: 20px;\n  }\n"])));
export var ContainerIconSkeleton = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-right: 0.5rem;\n  position: fixed;\n  right: 0;\n  margin-top: 44px;\n"], ["\n  padding-right: 0.5rem;\n  position: fixed;\n  right: 0;\n  margin-top: 44px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
