import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import "moment/locale/th";
import { useGenerateToken, useSaveQueryParams, useGetInqueryQuestion, } from "../../services/home/home-query";
import { getToken, useRouter, getLatestSubmissionInfo, getQuestions, getFieldLang, getResponse, } from "../../utils/helper";
import * as paths from "../../constants/paths";
import { useApplication } from "../../App.context";
import humps from "humps";
import Loading from "../../components/common/Loading";
var Home = function () {
    var _a = useRouter(), query = _a.query, navigate = _a.navigate;
    var saveQueryParams = useSaveQueryParams().mutate;
    var genToken = useGenerateToken().mutate;
    var _b = useGetInqueryQuestion(), responseQuestion = _b.data, getInqueryQuestion = _b.mutate, isLoading = _b.isLoading;
    var _c = useApplication(), appTheme = _c.appTheme, setisLoading = _c.setisLoading;
    var _d = humps.decamelizeKeys(query), authorization_code = _d.authorization_code, ref_id = _d.ref_id, channel = _d.channel, lang = _d.lang, survey_name = _d.survey_name, survey_group_id = _d.survey_group_id, survey_subgroup_id = _d.survey_subgroup_id;
    var token = getToken();
    useEffect(function () {
        document.documentElement.lang = lang.toLowerCase();
    }, [lang]);
    useEffect(function () {
        var params = {
            authorization_code: authorization_code || "",
            ref_id: ref_id || "",
            survey_group_id: Number(survey_group_id) || 0,
            survey_subgroup_id: Number(survey_subgroup_id) || 0,
            channel: channel.toString().toUpperCase() || "",
            lang: lang || "",
            survey_name: survey_name || "",
        };
        saveQueryParams(params);
    }, [saveQueryParams, query]);
    useEffect(function () {
        genToken({
            channel: (channel === null || channel === void 0 ? void 0 : channel.toString().toUpperCase()) || "",
            ref_id: ref_id || "",
            authorization_code: authorization_code || "",
            survey_group_id: Number(survey_group_id) || null,
            survey_subgroup_id: Number(survey_subgroup_id) || null,
            survey_name: survey_name || "",
        });
    }, [genToken, query]);
    useEffect(function () {
        if (token && token.length > 0) {
            getInqueryQuestion({
                survey_subgroup_id: Number(survey_subgroup_id) || null,
            });
            setisLoading(isLoading);
        }
    }, [token, getInqueryQuestion]);
    useEffect(function () {
        if (responseQuestion) {
            setisLoading(isLoading);
            var introInfo = getQuestions();
            var latestSubmissionInfo = getLatestSubmissionInfo();
            var totalSubmission = getResponse();
            if (!latestSubmissionInfo) {
                return navigate(paths.survey());
            }
            var payload = {
                title: getFieldLang(introInfo.dynamic_label.introduction, "title", lang ? lang.toLowerCase() : "th"),
                latestSubmissionDatetimeLabel: latestSubmissionInfo.latest_submission_datetime_label || "NO_DATA",
                latestSubmissionDatetime: latestSubmissionInfo.latest_submission_datetime || "NO_DATA",
                testResultLabel: latestSubmissionInfo.result_label || "NO_DATA",
                testResult: latestSubmissionInfo.result || "NO_DATA",
                subTitle: getFieldLang(introInfo.dynamic_label.introduction, "text1", lang ? lang.toLowerCase() : "th"),
                retryButton: getFieldLang(introInfo.dynamic_label, "start_survey_text", lang ? lang.toLowerCase() : "th"),
                submissionLimit: introInfo.submission_limits,
                totalSubmission: totalSubmission.total_submission,
                anableButton: getFieldLang(introInfo.dynamic_label, "unable_start_survey_text", lang ? lang.toLowerCase() : "th"),
            };
            return navigate(paths.introduction(), { state: payload });
        }
    }, [responseQuestion]);
    return _jsx(Loading, {});
};
export default Home;
