var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/system";
import Header from "../../Header";
import { useApplication } from "../../../App.context";
var MainLayout = function (props) {
    var Component = props.component, _a = props.closeIcon, closeIcon = _a === void 0 ? true : _a, color = props.color;
    var appTheme = useApplication().appTheme;
    return (_jsxs(Box, __assign({ sx: {
            background: color ? color : appTheme === null || appTheme === void 0 ? void 0 : appTheme.default.background.color,
            height: "100%",
        } }, { children: [_jsx(Header, { closeIcon: closeIcon }), _jsx(Component, {})] })));
};
export default MainLayout;
