var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Card from "../common/Card";
import Text from "../common/Text";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { RadioGroupField } from "../fields";
import { Divider } from "@mui/material";
import { Container } from "./style";
import { ReactComponent as RadioIcon } from "../../constants/icons/ic_radio.svg";
import { ReactComponent as RadioSelectedIcon } from "../../constants/icons/ic_radio_selected.svg";
import { useApplication } from "../../App.context";
import { getFieldLang, getLang } from "../../utils/helper";
import { DangerousDiv } from "../../pages/Introduction/Components/Content/style";
var QuestionCard = function (props) {
    var id = props.id, index = props.index, type = props.type, options = props.options, _a = props.divider, divider = _a === void 0 ? true : _a;
    var appTheme = useApplication().appTheme;
    var lang = getLang().toLowerCase();
    return (_jsxs(Container, { children: [_jsxs(Card, { children: [_jsx(Text, __assign({ type: "Bold", size: "16px", lineHeight: "24px", talk: "".concat(index, ". ").concat(getFieldLang(props, "title_voiceover", lang)) }, { children: "".concat(index, ". ").concat(getFieldLang(props, "title", lang)) })), type === "radio" && (_jsx(RadioGroupField, __assign({ required: true, "aria-label": getFieldLang(props, "title", lang), name: "questions.question".concat(id.toString()), row: true }, { children: options === null || options === void 0 ? void 0 : options.map(function (op) {
                            var id = op.id, value = op.value;
                            var answerValue = {
                                id: id,
                                value: value,
                            };
                            return (_jsx(FormControlLabel, { "aria-label": getFieldLang(op, "label_voiceover", lang), label: _jsx(DangerousDiv, { dangerouslySetInnerHTML: {
                                        __html: getFieldLang(op, "label", lang),
                                    } }), value: JSON.stringify(answerValue), control: _jsx(Radio, { icon: _jsx(RadioIcon, { width: 24, height: 24, color: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.option.color }), checkedIcon: _jsx(RadioSelectedIcon, { width: 24, height: 24, color: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.option.color, stroke: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.option.color }), style: { backgroundColor: "transparent" } }) }, "".concat(id, "-").concat(getFieldLang(op, "label", lang))));
                        }) })))] }), divider && _jsx(Divider, { variant: "middle", "aria-hidden": "true" })] }));
};
export default QuestionCard;
