var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Typography } from "@mui/material";
import FlexCard from "../../../../components/common/FlexCard";
import { SubContainer } from "../../style";
import { Container, EmptySpace, StyledImage, StyledLayoutText, StyledLayoutTextContent, StyledLayoutTextResult, StyledLayoutTextFooter, DangerousDiv, } from "./style";
import { GRAY_5A6E7B } from "../../../../constants/colors";
import { useApplication } from "../../../../App.context";
import Footer from "../Footer";
import { useState } from "react";
import ImagePlaceholder from "../../../../components/common/Placeholder/image";
import ReactDOMServer from "react-dom/server";
import { formatDatetime } from "../../../../utils/helper";
import Text from "../../../../components/common/Text";
var Content = function (_a) {
    var title = _a.title, latestSubmissionDatetimeLabel = _a.latestSubmissionDatetimeLabel, latestSubmissionDatetime = _a.latestSubmissionDatetime, testResultLabel = _a.testResultLabel, testResult = _a.testResult, subTitle = _a.subTitle, retryButton = _a.retryButton, submissionLimit = _a.submissionLimit, totalSubmission = _a.totalSubmission, anableButton = _a.anableButton;
    var appTheme = useApplication().appTheme;
    var _b = useState(true), fallbackImage = _b[0], setFallbackImage = _b[1];
    var imagePlaceholder = ReactDOMServer.renderToString(_jsx(ImagePlaceholder, { bg: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.placeholder_color_1, fg: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.placeholder_color_2 }));
    return (_jsx(Container, { children: _jsxs(SubContainer, { children: [_jsxs(FlexCard, __assign({ topImage: _jsx(StyledImage, { fallbackImage: fallbackImage, draggable: "false", src: (appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.intro_image_url)
                            ? appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.intro_image_url
                            : "", onError: function (_a) {
                            var currentTarget = _a.currentTarget;
                            currentTarget.onerror = null;
                            currentTarget.src = "data:image/svg+xml,".concat(encodeURIComponent(imagePlaceholder));
                            setFallbackImage(false);
                        }, alt: "Introduction Image" }) }, { children: [_jsx(StyledLayoutText, { children: _jsx(Text, __assign({ size: "20px", lineHeight: "30px", type: "Bold" }, { children: _jsx(DangerousDiv, { dangerouslySetInnerHTML: { __html: title } }) })) }), _jsx(Divider, { sx: { borderColor: "#5A6E7B1A", height: "1px" }, "aria-hidden": "true" }), _jsxs(StyledLayoutTextContent, { children: [_jsxs(StyledLayoutTextResult, { children: [_jsx(Typography, __assign({ sx: {
                                                fontWeight: 400,
                                                color: GRAY_5A6E7B,
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                "@media (max-width:280px)": {
                                                    fontSize: "75%",
                                                },
                                            } }, { children: latestSubmissionDatetimeLabel })), _jsx(Typography, __assign({ sx: {
                                                fontWeight: 700,
                                                color: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.result.color,
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                "@media (max-width:280px)": {
                                                    fontSize: "75%",
                                                },
                                            }, "aria-label": formatDatetime(latestSubmissionDatetime) }, { children: latestSubmissionDatetime }))] }), _jsxs(StyledLayoutTextResult, { children: [_jsx(Typography, __assign({ sx: {
                                                fontWeight: 400,
                                                color: GRAY_5A6E7B,
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                "@media (max-width:280px)": {
                                                    fontSize: "75%",
                                                },
                                            } }, { children: testResultLabel })), _jsx(Typography, __assign({ sx: {
                                                fontWeight: 400,
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                "@media (max-width:280px)": {
                                                    fontSize: "75%",
                                                },
                                            } }, { children: testResult }))] })] })] })), _jsx(StyledLayoutTextFooter, { children: _jsx(_Fragment, { children: _jsx(Typography, __assign({ sx: {
                                fontWeight: 400,
                                color: GRAY_5A6E7B,
                                fontSize: "14px",
                                lineHeight: "21px",
                            } }, { children: _jsx(DangerousDiv, { dangerouslySetInnerHTML: { __html: subTitle } }) })) }) }), _jsx(EmptySpace, {}), _jsx(Footer, { retryButton: retryButton, submissionLimit: submissionLimit, totalSubmission: totalSubmission, anableButton: anableButton })] }) }));
};
export default Content;
