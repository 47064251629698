var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import qs from "qs";
export var ContentType;
(function (ContentType) {
    ContentType["XFORM"] = "application/x-www-form-urlencoded";
    ContentType["JSON"] = "application/json";
    ContentType["FORMDATA"] = "multipart/form-data";
})(ContentType || (ContentType = {}));
var customClientWithData = function (url, method, data, option) {
    var _a = option || {}, _b = _a.contentType, contentType = _b === void 0 ? ContentType.JSON : _b, restOption = __rest(_a, ["contentType"]);
    return method(url, data, __assign({ headers: {
            "Content-Type": contentType,
        } }, restOption));
};
var customClient = function (url, method, params, option) {
    var _a = option || {}, _b = _a.contentType, contentType = _b === void 0 ? ContentType.JSON : _b, restOption = __rest(_a, ["contentType"]);
    return method(url, __assign({ params: params, headers: {
            "Content-Type": contentType,
        } }, restOption));
};
export var createMethod = function (client, apiWraper) { return ({
    get: function (url, param, option) { return apiWraper(customClient(url, client.get, param, option)); },
    put: function (url, data, option) {
        return apiWraper(customClientWithData(url, client.put, data, option));
    },
    post: function (url, data, option) {
        return apiWraper(customClientWithData(url, client.post, data, option));
    },
    patch: function (url, data, option) {
        return apiWraper(customClientWithData(url, client.patch, data, option));
    },
    delete: function (url, param, option) {
        return apiWraper(customClient(url, client.delete, param, option));
    },
    getFile: function (url, param, option) {
        return apiWraper(customClient(url, client.get, param, __assign({ responseType: "blob" }, option)));
    },
}); };
export var customRequestData = function (request) {
    if (request.headers["Content-Type"] === ContentType.FORMDATA) {
        if (request.data) {
            var formData_1 = new FormData();
            Object.entries(request.data).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (value instanceof Array) {
                    value.forEach(function (val) {
                        formData_1.append("".concat(key), val);
                    });
                }
                else {
                    formData_1.append(key, value);
                }
            });
            request.data = formData_1;
        }
    }
    else if (request.headers["Content-Type"] === ContentType.XFORM) {
        if (request.data) {
            request.data = qs.stringify(request.data);
        }
    }
    else if (request.headers["Content-Type"] === ContentType.JSON) {
    }
};
export var deepLoop = function (data, func) {
    if (data instanceof File) {
        return func(data);
    }
    if (data instanceof Array) {
        return data.map(function (d) { return deepLoop(d, func); });
    }
    if (data instanceof Object) {
        var formatData_1 = {};
        Object.keys(data).forEach(function (key) {
            formatData_1[key] = deepLoop(data[key], func);
        });
        return formatData_1;
    }
    return func(data);
};
