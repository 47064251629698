var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { StyledImage } from "./style";
import { GREEN_63BE00, RED_DB0000 } from "../../../constants/colors";
import Text from "../Text";
import CorrectIcon from "../../../constants/images/correct_icon.svg";
import InorrectIcon from "../../../constants/images/incorrect_icon.svg";
var ResultText = function (props) {
    var isCorrect = props.isCorrect, text = props.text;
    return (_jsxs(Box, __assign({ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }, { children: [_jsx(Box, { children: isCorrect ? (_jsx(StyledImage, { src: CorrectIcon, "aria-hidden": "true" })) : (_jsx(StyledImage, { src: InorrectIcon, "aria-hidden": "true" })) }), text && (_jsx(Text, __assign({ type: "Bold", weight: 700, color: isCorrect ? GREEN_63BE00 : RED_DB0000, size: "16px", lineHeight: "24px", talk: text }, { children: text })))] })));
};
export default ResultText;
