var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Text from "../../../../components/common/Text";
import { closeWebView, useRouter } from "../../../../utils/helper";
import { useApplication } from "../../../../App.context";
import { Box } from "@mui/material";
import * as paths from "../../../../constants/paths";
import { ButtonFooter } from "../../../../components/common/Footer";
var Footer = function (_a) {
    var retryButton = _a.retryButton, submissionLimit = _a.submissionLimit, totalSubmission = _a.totalSubmission, anableButton = _a.anableButton;
    var appTheme = useApplication().appTheme;
    var navigate = useRouter().navigate;
    var onClickButton = function () {
        if (isCantRetry()) {
            closeWebView();
        }
        else {
            navigate(paths.survey());
        }
    };
    var isCantRetry = function () { return submissionLimit === totalSubmission; };
    return (_jsx(Box, __assign({ sx: {
            bottom: 0,
            boxSizing: "border-box",
            left: 0,
            paddingBottom: "40px",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "0px",
            width: "100%",
            position: "fixed",
        } }, { children: _jsx(ButtonFooter, __assign({ onClick: onClickButton, talk: retryButton, backgroundColor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.default.button.background_color }, { children: _jsx(Text, __assign({ color: "white", type: "Bold", size: "16px", lineHeight: "24px" }, { children: isCantRetry() ? anableButton : retryButton })) })) })));
};
export default Footer;
