var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { GRAY_EAF0F5 } from "../../../constants/colors";
import { Button } from "@mui/material";
import { getXSurveyDest } from "../../../utils/helper";
export var StyledButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: 8px !important;\n  width: 100%;\n  height: 48px;\n"], ["\n  background: ", ";\n  border-radius: 8px !important;\n  width: 100%;\n  height: 48px;\n"])), function (_a) {
    var valid = _a.valid, backgroundColor = _a.backgroundColor;
    return valid === "true"
        ? backgroundColor + "!important"
        : getXSurveyDest() === "ibank"
            ? "#E0E0E0 !important"
            : GRAY_EAF0F5 + "!important";
});
export var StyledFooter = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  bottom: 0;\n  box-sizing: border-box;\n  left: 0;\n  padding-bottom: 40px;\n  padding-left: 16px;\n  padding-right: 16px;\n  padding-top: 0px;\n  background-color: ", ";\n  position: ", ";\n  width: 100vw;\n  z-index: 1;\n"], ["\n  bottom: 0;\n  box-sizing: border-box;\n  left: 0;\n  padding-bottom: 40px;\n  padding-left: 16px;\n  padding-right: 16px;\n  padding-top: 0px;\n  background-color: ", ";\n  position: ", ";\n  width: 100vw;\n  z-index: 1;\n"])), function (_a) {
    var backgroundColor = _a.backgroundColor;
    return backgroundColor;
}, function (_a) {
    var isRelative = _a.isRelative;
    return (isRelative ? "relative" : "fixed");
});
var templateObject_1, templateObject_2;
