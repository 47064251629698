var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import FooterComponent, { ButtonFooter, } from "../../../../components/common/Footer";
import Text from "../../../../components/common/Text";
import { FormSpy } from "react-final-form";
import { GRAY_8A9FAB } from "../../../../constants/colors";
import { useCallback, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { useApplication } from "../../../../App.context";
import { getFieldLang, getLang, getQuestions, getXSurveyDest, } from "../../../../utils/helper";
var Footer = function (props) {
    var onClickButtonProp = props.onClickButton;
    var _a = useState(true), isButonDisabled = _a[0], setIsButonDisabled = _a[1];
    var data = getQuestions();
    var lang = getLang().toLowerCase();
    var onClickButton = useCallback(function () {
        setIsButonDisabled(false);
        onClickButtonProp === null || onClickButtonProp === void 0 ? void 0 : onClickButtonProp();
    }, [setIsButonDisabled, onClickButtonProp]);
    var appTheme = useApplication().appTheme;
    return (_jsx(FormSpy, __assign({ subscription: {
            valid: true,
        } }, { children: function (_a) {
            var valid = _a.valid;
            return (_jsx(FooterComponent, __assign({ backgroundColor: "white" }, { children: props.isSkeleton ? (_jsx(Skeleton, { variant: "rounded", animation: "wave", width: "100%", height: 48, style: {
                        borderRadius: "0.75rem",
                        marginLeft: "auto",
                        marginRight: "auto",
                        maxWidth: "343px",
                    }, sx: { bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color } })) : (_jsx(ButtonFooter, __assign({ type: "submit", isValid: valid && isButonDisabled, onClick: onClickButton, talk: getFieldLang(data.dynamic_label, "submit_text", lang), backgroundColor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.default.button.background_color }, { children: _jsx(Text, __assign({ color: valid
                            ? "white"
                            : getXSurveyDest() === "ibank"
                                ? "white"
                                : GRAY_8A9FAB, type: "Bold", size: "16px", lineHeight: "24px" }, { children: getFieldLang(data.dynamic_label, "submit_text", lang) })) }))) })));
        } })));
};
export default Footer;
