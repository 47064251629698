var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { QueryClient, QueryClientProvider } from "react-query";
import * as paths from "../constants/paths";
import { ERROR } from "../services/error/error-query";
import sha256 from "crypto-js/sha256";
import { GENERATE_TOKEN_IBANK, GENERATE_TOKEN_NEXT, GENERATE_TOKEN_PT, QUESTIONAIRE_INQUIRY, } from "../services/home/home-query";
import { QUESTIONAIRE_ANSWER_INQUIRY } from "../services/success/success-query";
export var useRouter = function () {
    var params = useParams();
    var location = useLocation();
    var navigate = useNavigate();
    return useMemo(function () {
        return {
            push: navigate,
            pathname: location.pathname,
            query: __assign(__assign({}, queryString.parse(location.search.slice(1))), params),
            location: location,
            navigate: navigate,
        };
    }, [location, navigate, params]);
};
export var clearSession = function () {
    sessionStorage.clear();
};
export var setParamURL = function (result) {
    sessionStorage.setItem("PARAMURL", JSON.stringify(result));
};
var jsonParseSessionItem = function (sessionKey) {
    try {
        return JSON.parse(sessionStorage.getItem(sessionKey) || "");
    }
    catch (_a) {
        return undefined;
    }
};
export var getParamURL = function () {
    return jsonParseSessionItem("PARAMURL");
};
export var setLatestSubmissionInfo = function (result) {
    sessionStorage.setItem("LATESTSUBMISSIONINFO", JSON.stringify(result));
};
export var getLatestSubmissionInfo = function () {
    return jsonParseSessionItem("LATESTSUBMISSIONINFO");
};
export var setResponse = function (response) {
    sessionStorage.setItem("RESPONSE", JSON.stringify(response));
};
export var getResponse = function () {
    return jsonParseSessionItem("RESPONSE");
};
export var setQuestions = function (result) {
    sessionStorage.setItem("QUESTIONS", JSON.stringify(result));
};
export var getQuestions = function () {
    return jsonParseSessionItem("QUESTIONS");
};
export var setResult = function (result) {
    sessionStorage.setItem("QUESTION_RESULT", JSON.stringify(result));
};
export var getResult = function () {
    return jsonParseSessionItem("QUESTION_RESULT");
};
export var resetToken = function () {
    sessionStorage.removeItem("AUTH_TOKEN");
};
export var setToken = function (token) {
    sessionStorage.setItem("AUTH_TOKEN", token);
};
export var getToken = function () {
    return sessionStorage.getItem("AUTH_TOKEN");
};
export var getTraceId = function () {
    return sessionStorage.getItem("TRACE_ID");
};
export var setTraceId = function (traceId) {
    sessionStorage.setItem("TRACE_ID", traceId);
};
export var genTraceId = function () {
    var traceId = sha256(Math.random().toString()).toString().substring(0, 32);
    setTraceId(traceId);
    return traceId;
};
export var closeWebView = function () {
    var command = "closeWebview";
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1;
    if (isAndroid) {
        // @ts-ignore
        window.JSBridge.closeWebview("");
    }
    else if (window.webkit) {
        window.webkit.messageHandlers.observer.postMessage({
            command: command,
        });
    }
};
export var getTraceParent = function () {
    var version = "00";
    var traceId = getTraceId();
    if (!traceId) {
        traceId = genTraceId();
    }
    var parentId = sha256(Math.random().toString()).toString().substring(0, 16); // 16 hex string
    var traceFlags = "01";
    return "".concat(version, "-").concat(traceId, "-").concat(parentId, "-").concat(traceFlags);
};
export var getXSurveyDest = function () {
    var _a;
    var channel = (_a = getParamURL()) === null || _a === void 0 ? void 0 : _a.channel.toString().toUpperCase();
    switch (channel) {
        case "NEXT":
            return "ktb";
        case "PAOTANG":
            return "ktb";
        default:
            return "ibank";
    }
};
export var getLang = function () {
    var _a;
    var lang = (_a = getParamURL()) === null || _a === void 0 ? void 0 : _a.lang;
    return lang || "th";
};
var client = new QueryClient();
export var CustomQueryClientProvider = function (props) {
    var children = props.children;
    var navigate = useRouter().navigate;
    var onErrorHandler = useCallback(function (error) {
        var status = error.status, code = error.code, message = error.message, exp = error.exp;
        console.log("error", error);
        client.setQueryData([ERROR], {
            status: status,
            data: {
                code: code,
                message: message,
                exp: exp,
            },
        });
        navigate(paths.error());
    }, [navigate]);
    useMemo(function () {
        client.setDefaultOptions({
            queries: {
                cacheTime: 30 * 1000,
                staleTime: 30 * 1000,
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                retry: 0,
                onError: onErrorHandler,
            },
            mutations: {
                retry: 0,
                onError: onErrorHandler,
            },
        });
    }, [onErrorHandler]);
    return (_jsx(QueryClientProvider, __assign({ client: client }, { children: children && children })));
};
export var getFieldLang = function (data, field, lang) {
    if (!data || !data["".concat(field, "_").concat(lang)] || data === undefined)
        return "NO_DATA";
    return data["".concat(field, "_").concat(lang)];
};
export var getCaseErrorMassage = function (url, code, message) {
    if (url.includes("/web-survey-template-prd/questionnaires")) {
        return "cannot get questionnaires";
    }
    else if (url.includes("/web-survey-template-prd/themes")) {
        return "cannot get themes";
    }
    else if (url.includes(QUESTIONAIRE_INQUIRY)) {
        return "cannot get question inquiry";
    }
    else if (url.includes(GENERATE_TOKEN_IBANK) ||
        url.includes(GENERATE_TOKEN_PT) ||
        url.includes(GENERATE_TOKEN_NEXT)) {
        return "cannot generate token";
    }
    else if (url.includes(QUESTIONAIRE_ANSWER_INQUIRY)) {
        return "cannot submit answer";
    }
};
export var errorHeaderTitle = function () {
    var lang = getParamURL().lang;
    switch (lang) {
        case "TH":
            return "แบบทดสอบความรู้";
        case "EN":
            return "Awareness Test";
        default:
            return "แบบทดสอบความรู้";
    }
};
export var formatDatetime = function (date) {
    var month = date.split(" ")[1];
    switch (month) {
        case "ม.ค.": {
            return date.replace("ม.ค.", "มกราคม");
        }
        case "ก.พ.": {
            return date.replace("ก.พ.", "กุมภาพันธ์");
        }
        case "มี.ค.": {
            return date.replace("มี.ค.", "มีนาคม");
        }
        case "เม.ย.": {
            return date.replace("เม.ย.", "เมษายน");
        }
        case "พ.ค.": {
            return date.replace("พ.ค.", "พฤษภาคม");
        }
        case "มิ.ย.": {
            return date.replace("มิ.ย.", "มิถุนายน");
        }
        case "ก.ค.": {
            return date.replace("ก.ค.", "กรกฎาคม");
        }
        case "ส.ค.": {
            return date.replace("ส.ค.", "สิงหาคม");
        }
        case "ก.ย.": {
            return date.replace("ก.ย.", "กันยายน");
        }
        case "ต.ค.": {
            return date.replace("ต.ค.", "ตุลาคม");
        }
        case "พ.ย.": {
            return date.replace("พ.ย.", "พฤศจิกายน");
        }
        case "ธ.ค.": {
            return date.replace("ธ.ค.", "ธันวาคม");
        }
        default:
            return date;
    }
};
