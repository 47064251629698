//SOLID COLORS//
export var BLACK_212121 = "#212121";
export var BLACK_000000 = "#000000";
export var BLUE_004C7C = "#004C7C";
export var BLUE_025BB7 = "#025BB7";
export var BLUE_00A6E6 = "#00A6E6";
export var GRAY_F8F8F8 = "#F8F8F8";
export var GRAY_838383 = "#838383";
export var GRAY_565656 = "#565656";
export var GRAY_E0E0E0 = "#E0E0E0";
export var GRAY_EAF0F5 = "#EAF0F5";
export var GRAY_F2F2F2 = "#F2F2F2";
export var GRAY_F2F4F7 = "#F2F4F7";
export var GRAY_CACACA = "#CACACA";
export var GRAY_F9FAFB = "#F9FAFB";
export var GRAY_8A9FAB = "#8A9FAB";
export var GRAY_5A6E7B = "#5A6E7B";
export var GRAY_BCCCD6 = "#BCCCD6";
export var GREEN_70B412 = "#70B412";
export var GREEN_63BE00 = "#63BE00";
export var RED_DB0000 = "#DB0000";
export var RED_FFF0F0 = "#FFF0F0";
export var WHITE_FFFFFF = "#FFFFFF";
export var BLUE_E1F1FF = "#E1F1FF";
export var PRIMARY_007BC4 = "#007BC4";
export var BLUE_C7E3F4 = "#C7E3F4";
export var BLUE_007BC4 = "#007BC4";
//GRADIENT COLORS//
export var GRADIENT_PRIMARY = "linear-gradient(62.12deg, #007BC4 0%, #04BAEE 100%)";
