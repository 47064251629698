import qs from "qs";
var generate = function (url) {
    return function (option) {
        var _a = option || {}, routeParam = _a.routeParam, queryParam = _a.queryParam;
        var newQueryParam = "";
        if (queryParam) {
            newQueryParam = "?".concat(qs.stringify(queryParam));
        }
        var newUrl = url;
        if (routeParam) {
            var urls = url.split("/");
            newUrl = urls
                .map(function (u) {
                if (/:.+/.test(u)) {
                    return routeParam[u.slice(1)];
                }
                return u;
            })
                .join("/");
        }
        return "".concat(newUrl).concat(newQueryParam);
    };
};
export var home = generate("/");
export var survey = generate("/survey");
export var finish = generate("/finish");
export var introduction = generate("/introduction");
export var success = generate("/success");
export var error = generate("/error");
