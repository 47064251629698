var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  overflow: auto;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  overflow: auto;\n"])));
export var StyledLayoutText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n"])));
export var ContainerImage = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-radius: 16px;\n  padding-top: 24px;\n  max-width: 375px;\n  padding-bottom: 65px;\n"], ["\n  border-radius: 16px;\n  padding-top: 24px;\n  max-width: 375px;\n  padding-bottom: 65px;\n"])));
export var StyledImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  max-height: 457px;\n  border-radius: 16px;\n  object-fit: fit;\n"], ["\n  width: 100%;\n  height: 100%;\n  max-height: 457px;\n  border-radius: 16px;\n  object-fit: fit;\n"])));
export var EmptySpace = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 48px;\n  display: flex;\n  flex-direction: column;\n"], ["\n  height: 48px;\n  display: flex;\n  flex-direction: column;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
