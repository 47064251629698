var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { StyledCard, StyledImageTop } from "./style";
var FlexCard = function (props) {
    var children = props.children, className = props.className, topImage = props.topImage, bgColor = props.bgColor, fullHeight = props.fullHeight, marginX = props.marginX, marginY = props.marginY, paddingX = props.paddingX, paddingY = props.paddingY, paddingTop = props.paddingTop, boxShadow = props.boxShadow;
    return (_jsxs(_Fragment, { children: [topImage && _jsx(StyledImageTop, { children: topImage }), _jsx(StyledCard, __assign({ className: className, topImage: topImage, bgColor: bgColor, fullHeight: fullHeight, marginX: marginX, marginY: marginY, paddingX: paddingX, paddingY: paddingY, paddingTop: paddingTop, boxShadow: boxShadow }, { children: children }))] }));
};
export default FlexCard;
