var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HeaderSpace from "../../HeaderSpace";
import { useApplication } from "../../../App.context";
import { Box } from "@mui/system";
var ImageLayout = function (props) {
    var Component = props.component;
    var appTheme = useApplication().appTheme;
    return (_jsxs(Box, __assign({ sx: {
            background: appTheme === null || appTheme === void 0 ? void 0 : appTheme.default.background.color,
            height: "100vh",
        } }, { children: [_jsx(HeaderSpace, {}), _jsx(Component, {})] })));
};
export default ImageLayout;
