var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from "../common/Text";
import { Container, ContainerContent, ContainerIcon } from "./styled";
import { IconButton } from "@mui/material";
import { useCallback } from "react";
import { closeWebView, errorHeaderTitle, getFieldLang, getLang, getQuestions, useRouter, } from "../../utils/helper";
import Skeleton from "@mui/material/Skeleton";
import { useApplication } from "../../App.context";
import CloseIcon from "../common/CloseIcon";
import * as paths from "../../constants/paths";
var Header = function (prop) {
    var _a = prop.closeIcon, closeIconProp = _a === void 0 ? true : _a;
    var appTheme = useApplication().appTheme;
    var data = getQuestions();
    var lang = getLang().toLowerCase();
    var onClickIcon = useCallback(function () {
        closeWebView();
    }, []);
    var pathname = useRouter().pathname;
    var isErrorPage = pathname === paths.error();
    return (_jsx(Container, { children: _jsxs(ContainerContent, { children: [(data === null || data === void 0 ? void 0 : data.dynamic_label) || isErrorPage ? (_jsx(Text, __assign({ type: "Bold", lineHeight: "30px", size: "20px", talk: isErrorPage
                        ? errorHeaderTitle()
                        : getFieldLang(data.dynamic_label, "header_text", lang) }, { children: isErrorPage
                        ? errorHeaderTitle()
                        : getFieldLang(data.dynamic_label, "header_text", lang) }))) : (_jsx(Skeleton, { variant: "rounded", width: "100%", animation: "wave", sx: {
                        height: "20px",
                        bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                        borderRadius: "6px",
                        maxWidth: "149px",
                    } })), closeIconProp && (_jsx(ContainerIcon, __assign({ onClick: onClickIcon, role: "button" }, { children: _jsx(IconButton, __assign({ size: "small", "aria-label": lang === "th" ? "ปิด" : lang === "en" ? "close" : "ปิด" }, { children: _jsx(CloseIcon, {}) })) })))] }) }));
};
export default Header;
