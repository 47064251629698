var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useApplication } from "../../../App.context";
import { Box } from "@mui/material";
export default function CloseIcon(_a) {
    var LoadingColor = _a.LoadingColor;
    var appTheme = useApplication().appTheme;
    return (_jsx(Box, __assign({ sx: { zIndex: 1000 } }, { children: _jsx("svg", __assign({ width: "21", height: "20", viewBox: "0 0 21 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M1.6093 0.206926C1.21563 -0.096312 0.648526 -0.0660035 0.289685 0.296116C-0.0990592 0.688412 -0.0961801 1.32157 0.296116 1.71031L8.58657 10.0004L0.29751 18.2873C-0.0947858 18.6761 -0.0976641 19.3092 0.29108 19.7015C0.649922 20.0637 1.21702 20.094 1.61069 19.7907L1.70528 19.708L10.0008 11.4146L18.2964 19.7098L18.391 19.7925C18.7846 20.0958 19.3517 20.0655 19.7106 19.7034C20.0993 19.3111 20.0964 18.6779 19.7041 18.2892L11.4152 10.0006L19.7058 1.71202C20.0981 1.32328 20.101 0.690121 19.7123 0.297825C19.3534 -0.0642945 18.7863 -0.094603 18.3927 0.208635L18.2981 0.291394L10.0009 8.58639L1.70388 0.289685L1.6093 0.206926Z", fill: LoadingColor || (appTheme === null || appTheme === void 0 ? void 0 : appTheme.default.button.color) }) })) })));
}
