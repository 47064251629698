var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import VConsole from "vconsole";
import { CustomQueryClientProvider } from "./utils/helper";
import ApplicationProvider from "./App.context";
var Theme = createTheme({
    typography: {
        fontFamily: "KrungthaiFast-Regular",
        button: {
            textTransform: "none",
        },
    },
});
var App = function () {
    useEffect(function () {
        if (process.env.REACT_APP_TOGGLE_VCONSOLE === "true") {
            new VConsole();
        }
    }, []);
    useEffect(function () {
        document.body.style.overflow = "hidden";
        document.title = "Survey";
    }, []);
    return (_jsx(BrowserRouter, { children: _jsx(ApplicationProvider, { children: _jsx(CustomQueryClientProvider, { children: _jsxs(ThemeProvider, __assign({ theme: Theme }, { children: [_jsx(Routes, {}), _jsx(ReactQueryDevtools, { initialIsOpen: false })] })) }) }) }));
};
export default App;
