var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { GRAY_F9FAFB } from "../../../../constants/colors";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n\n  padding-left: 24px;\n  padding-right: 24px;\n  box-sizing: border-box;\n\n  padding-top: 80px;\n  text-align: center;\n"], ["\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n\n  padding-left: 24px;\n  padding-right: 24px;\n  box-sizing: border-box;\n\n  padding-top: 80px;\n  text-align: center;\n"])), GRAY_F9FAFB);
export var StyledImage = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  max-width: 240px;\n  max-height: 180px;\n  object-fit: fit;\n  align-self: center;\n"], ["\n  width: 100%;\n  height: 100%;\n  max-width: 240px;\n  max-height: 180px;\n  object-fit: fit;\n  align-self: center;\n"])));
export var ContainerTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 16px;\n"], ["\n  margin-top: 16px;\n"])));
export var ContainerDetail = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 8px;\n"], ["\n  margin-top: 8px;\n"])));
export var ContainerErrorCode = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 16px;\n"], ["\n  margin-top: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
