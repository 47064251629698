var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useApplication } from "../../App.context";
import Content from "./Components/Content";
import { Container } from "./style";
var Success = function () {
    var appTheme = useApplication().appTheme;
    useEffect(function () {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (_jsx(Container, __assign({ backgroundColor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.default.background.color }, { children: _jsx(Content, {}) })));
};
export default Success;
