import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes as RoutesLib } from "react-router-dom";
import MainLayout from "../components/Layout/MainLayout";
import ImageLayout from "../components/Layout/ImageLayout";
import Finish from "../pages/Finish";
import Home from "../pages/Home";
import Error from "../pages/Error";
import Introduction from "../pages/Introduction";
import Success from "../pages/Success";
import * as paths from "../constants/paths";
import Survey from "../pages/Survey";
import { GRAY_F9FAFB } from "../constants/colors";
var Routes = function () {
    return (_jsxs(RoutesLib, { children: [_jsx(Route, { element: _jsx(Home, {}), path: paths.home(), errorElement: paths.error() }), _jsx(Route, { element: _jsx(MainLayout, { component: Finish }), path: paths.finish() }), _jsx(Route, { element: _jsx(MainLayout, { component: Survey }), path: paths.survey() }), _jsx(Route, { element: _jsx(ImageLayout, { component: Introduction }), path: paths.introduction() }), _jsx(Route, { element: _jsx(ImageLayout, { component: Success }), path: paths.success() }), _jsx(Route, { element: _jsx(MainLayout, { component: Error, color: GRAY_F9FAFB }), path: paths.error() })] }));
};
export default Routes;
