var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import { makeStyles } from "@mui/material";
import { useMemo } from "react";
import { Field as FieldFinalForm } from "react-final-form";
import { StyledTextError } from "./styled";
export var modifyComponent = function (Component) { return function (props) {
    var input = props.input, meta = props.meta, style = props.style, className = props.className, onChange = props.onChange, name = props.name, required = props.required, 
    // hideErrorLabel = false,
    restProps = __rest(props, ["input", "meta", "style", "className", "onChange", "name", "required"]);
    var error = meta.error, touched = meta.touched;
    var iserror = useMemo(function () {
        return error && touched;
    }, [error, touched]);
    return (_jsxs("div", __assign({ style: style }, { children: [_jsx(Component, __assign({}, input, { "aria-required": required, onChange: function (v) {
                    input.onChange(v);
                    if (onChange) {
                        onChange(v);
                    }
                }, iserror: iserror === null || iserror === void 0 ? void 0 : iserror.toString(), meta: meta }, restProps)), iserror && required && (_jsx(StyledTextError, __assign({ color: "red" }, { children: error })))] })));
}; };
export var makeField = function (component) {
    var newComponent = modifyComponent(component);
    var returnField = function (props) { return _jsx(FieldFinalForm, __assign({}, props, { render: newComponent })); };
    return returnField;
};
