var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Skeleton } from "@mui/material";
import { GRAY_F9FAFB } from "../../../constants/colors";
import { StyledButton, StyledFooter } from "./styled";
import { BLUE_C7E3F4 } from "../../../constants/colors";
export var ButtonFooter = function (prop) {
    var children = prop.children, _a = prop.isValid, isValid = _a === void 0 ? true : _a, sx = prop.sx, onClick = prop.onClick, backgroundColor = prop.backgroundColor, _b = prop.isLoading, isLoading = _b === void 0 ? false : _b, restProps = __rest(prop, ["children", "isValid", "sx", "onClick", "backgroundColor", "isLoading"]);
    return isLoading ? (_jsx(Skeleton, { animation: "wave", variant: "rounded", sx: {
            bgcolor: BLUE_C7E3F4,
            borderRadius: "0.75rem",
            height: "48px",
            flexShrink: 0,
            width: "100%",
        } })) : (_jsx(StyledButton, __assign({ sx: sx, type: "submit", valid: isValid.toString(), disabled: !isValid, onClick: onClick, backgroundColor: backgroundColor }, restProps, { children: children })));
};
var FooterComponent = function (props) {
    var children = props.children, _a = props.backgroundColor, backgroundColor = _a === void 0 ? GRAY_F9FAFB : _a, _b = props.isRelative, isRelative = _b === void 0 ? false : _b;
    return (_jsx(StyledFooter, __assign({ backgroundColor: backgroundColor, isRelative: isRelative }, { children: children && children })));
};
export default FooterComponent;
