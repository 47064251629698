var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import dayjs from "dayjs";
import humps from "humps";
import { ContentType, deepLoop } from "./tools";
import qs from "qs";
import { getToken, getTraceParent, getXSurveyDest, getLang, getCaseErrorMassage, } from "../helper";
var BASE_TODO_API_HOST = "".concat(process.env.REACT_APP_GT_HOST);
var BASE_TODO_API_HOST_PREFIX = "".concat(process.env.REACT_APP_GT_PREFIX);
var createClient = function () {
    var ax = axios.create();
    ax.interceptors.request.use(function (request) {
        request.url = "".concat(BASE_TODO_API_HOST, "/").concat(getXSurveyDest(), "/").concat(BASE_TODO_API_HOST_PREFIX, "/").concat(request.url);
        var headers = request.headers || {};
        headers.common["Authorization"] = "Bearer ".concat(getToken());
        headers.common["Traceparent"] = getTraceParent();
        headers.common["x-survey-dest"] = getXSurveyDest();
        headers.common["Accept-Language"] = getLang();
        if (headers["Content-Type"] === ContentType.FORMDATA) {
            if (request.data) {
                var formData = new FormData();
                request.data = formData;
            }
        }
        else if (headers["Content-Type"] === ContentType.XFORM) {
            request.params = modifyRequestData(request.params);
            if (request.data) {
                request.data = modifyRequestData(request.data);
                request.data = qs.stringify(request.data);
            }
        }
        else if (headers["Content-Type"] === ContentType.JSON) {
            request.params = modifyRequestData(request.params);
            if (request.data) {
                request.data = deepLoop(request.data, modifyRequestData);
                request.data = humps.decamelizeKeys(request.data);
            }
        }
        return request;
    });
    ax.interceptors.response.use(function (response) {
        var data = response.data;
        if (data && data.code !== undefined && data.code !== 0) {
            return Promise.reject({ response: response });
        }
        else {
            return response;
        }
    }, function (error) {
        var response = error.response, config = error.config;
        return Promise.reject(__assign(__assign({}, response), { url: config.url }));
    });
    return ax;
};
var createClientPublic = function () {
    var ax = axios.create();
    ax.interceptors.request.use(function (request) {
        request.url = "".concat(request.url);
        var headers = request.headers || {};
        if (headers["Content-Type"] === ContentType.FORMDATA) {
            if (request.data) {
                var formData = new FormData();
                request.data = formData;
            }
        }
        else if (headers["Content-Type"] === ContentType.XFORM) {
            request.params = modifyRequestData(request.params);
            if (request.data) {
                request.data = modifyRequestData(request.data);
                request.data = qs.stringify(request.data);
            }
        }
        else if (headers["Content-Type"] === ContentType.JSON) {
            request.params = modifyRequestData(request.params);
            if (request.data) {
                request.data = deepLoop(request.data, modifyRequestData);
                request.data = humps.decamelizeKeys(request.data);
            }
        }
        return request;
    });
    ax.interceptors.response.use(function (response) {
        var data = response.data;
        if (data && data.code !== undefined && data.code !== 0) {
            return Promise.reject({ response: response });
        }
        else {
            return response;
        }
    }, function (error) {
        var response = error.response, config = error.config;
        return Promise.reject(__assign(__assign({}, response), { url: config.url }));
    });
    return ax;
};
var modifyRequestData = function (data) {
    if (dayjs.isDayjs(data)) {
        return data.format();
    }
    return data;
};
export var gtClient = createClient();
export var gtPublic = createClientPublic();
export var gtApiWrapper = function (method) { return __awaiter(void 0, void 0, void 0, function () {
    var res, e_1, accData, _a, message, code, data, url;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, method];
            case 1:
                res = _b.sent();
                return [2 /*return*/, Promise.resolve(res)];
            case 2:
                e_1 = _b.sent();
                accData = {};
                _a = (e_1 || {}), message = _a.message, code = _a.code, data = _a.data, url = _a.url;
                console.log("error", e_1);
                accData["status"] = (e_1 === null || e_1 === void 0 ? void 0 : e_1.status) ? e_1 === null || e_1 === void 0 ? void 0 : e_1.status : 500;
                accData["code"] = code;
                accData["message"] = getCaseErrorMassage(url, code, message);
                if (data && typeof data === "object") {
                    accData = __assign({}, data);
                }
                return [2 /*return*/, Promise.reject(accData || message || code || e_1)];
            case 3: return [2 /*return*/];
        }
    });
}); };
