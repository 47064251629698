var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import FlexCard from "../../../../../components/common/FlexCard";
import { EmptySpace, StyledImage, StyledLayoutText, StyledLayoutTextContent, StyledLayoutTextResult, StyledSkeletonResult, } from "../style";
import { Skeleton } from "@mui/material";
import { useApplication } from "../../../../../App.context";
import ImagePlaceholder from "../../../../../components/common/Placeholder/image";
import ReactDOMServer from "react-dom/server";
import { SubContainer } from "../../../style";
import { GRAY_F9FAFB } from "../../../../../constants/colors";
import Footer from "../../Footer";
var SkeletonUI = function () {
    var appTheme = useApplication().appTheme;
    var fallbackImage = !!(appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.result_image_url);
    var imagePlaceholder = ReactDOMServer.renderToString(_jsx(ImagePlaceholder, { bg: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.placeholder_color_1, fg: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.placeholder_color_2 }));
    var skeletonQuestions = [];
    // const isSkeleton = false;
    for (var index = 0; index < 3; index++) {
        skeletonQuestions.push(_jsxs(FlexCard, __assign({ marginY: 1, bgColor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_card_color }, { children: [_jsx(StyledLayoutTextContent, { children: _jsxs(StyledLayoutText, __assign({ style: { gap: "0.5rem", marginBottom: "0.75rem" } }, { children: [_jsx(Skeleton, { variant: "rounded", width: "100%", height: "16px", animation: "wave", sx: {
                                    bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                                    borderRadius: "0.375rem",
                                    alignSelf: "start",
                                } }), _jsx(Skeleton, { variant: "rounded", width: "163px", height: "16px", animation: "wave", sx: {
                                    fontSize: "20px",
                                    bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                                    borderRadius: "0.375rem",
                                    alignSelf: "start",
                                } })] })) }), _jsxs(StyledLayoutTextContent, { children: [_jsxs(StyledLayoutTextResult, __assign({ style: { gap: "0.75rem" } }, { children: [_jsx(Skeleton, { variant: "rounded", width: "120px", height: "16px", animation: "wave", sx: {
                                        fontSize: "20px",
                                        bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                                        borderRadius: "0.375rem",
                                    } }), _jsx(Skeleton, { variant: "rounded", width: "48px", height: "16px", animation: "wave", sx: {
                                        fontSize: "20px",
                                        bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                                        borderRadius: "0.375rem",
                                    } })] })), _jsxs(StyledLayoutTextResult, { children: [_jsx(Skeleton, { variant: "rounded", width: "120px", height: "16px", animation: "wave", sx: {
                                        fontSize: "20px",
                                        bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                                        borderRadius: "0.375rem",
                                    } }), _jsx(StyledSkeletonResult, { children: _jsx(Skeleton, { variant: "rounded", width: "64px", height: "16px", animation: "wave", sx: {
                                            fontSize: "20px",
                                            bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                                            borderRadius: "0.375rem",
                                        } }) })] })] })] })));
    }
    return (_jsx(SubContainer, __assign({ backgroundColor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.default.background.color }, { children: _jsxs(FlexCard, __assign({ bgColor: GRAY_F9FAFB, topImage: _jsx(Skeleton, __assign({ variant: "rounded", animation: "wave", sx: {
                    bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                    borderRadius: ".5rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                } }, { children: _jsx(StyledImage, { fallbackImage: fallbackImage, data: (appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.result_image_url)
                        ? appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.result_image_url
                        : "", draggable: "false", role: "img", "aria-label": "Skeleton Image", onError: function (_a) {
                        var currentTarget = _a.currentTarget;
                        currentTarget.onerror = null;
                        currentTarget.data = "data:image/svg+xml,".concat(encodeURIComponent(imagePlaceholder));
                    } }) })), paddingTop: "62px" }, { children: [_jsxs(StyledLayoutText, __assign({ style: { gap: "0.5rem", marginBottom: '7px' } }, { children: [_jsx(Skeleton, { variant: "rounded", width: "200px", height: "20px", animation: "wave", sx: {
                                fontSize: "30px",
                                bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                                borderRadius: "0.375rem",
                            } }), _jsx(Skeleton, { variant: "rounded", width: "149px", height: "20px", animation: "wave", sx: {
                                fontSize: "30px",
                                bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                                borderRadius: "0.375rem",
                            } })] })), skeletonQuestions, _jsx("div", __assign({ style: { marginTop: "32px" } }, { children: _jsx(Footer, { isLoading: true }) })), _jsx(EmptySpace, {})] })) })));
};
export default SkeletonUI;
