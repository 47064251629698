var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { BLUE_E1F1FF } from "../../constants/colors";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nbackground-color: ", ";\n\n  height: auto;\n  position: relative;\n  width: 100vw;\n  overflow: auto;\n  box-sizing: border-box;\n\n  ::-webkit-scrollbar {\n    display: none;\n    width: 0 !important;\n  }\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* IE 10+ */\n"], ["\nbackground-color: ", ";\n\n  height: auto;\n  position: relative;\n  width: 100vw;\n  overflow: auto;\n  box-sizing: border-box;\n\n  ::-webkit-scrollbar {\n    display: none;\n    width: 0 !important;\n  }\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* IE 10+ */\n"])), function (_a) {
    var backgroundColor = _a.backgroundColor;
    return backgroundColor ? backgroundColor : "".concat(BLUE_E1F1FF);
});
export var SubContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nbackground-color: ", ";\n\n  height: auto;\n  position: relative;\n  width: 100vw;\n  overflow: auto;\n  padding-left: 0;\n  padding-right: 0;\n  box-sizing: border-box;\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* IE 10+ */\n"], ["\nbackground-color: ", ";\n\n  height: auto;\n  position: relative;\n  width: 100vw;\n  overflow: auto;\n  padding-left: 0;\n  padding-right: 0;\n  box-sizing: border-box;\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* IE 10+ */\n"])), function (_a) {
    var backgroundColor = _a.backgroundColor;
    return backgroundColor ? backgroundColor : "".concat(BLUE_E1F1FF);
});
var templateObject_1, templateObject_2;
