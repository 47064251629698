import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container } from "./style";
import Content from "./Component/Content";
import Footer from "./Component/Footer";
import { useEffect } from "react";
var Error = function () {
    useEffect(function () {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (_jsxs(Container, { children: [_jsx(Content, {}), _jsx(Footer, {})] }));
};
export default Error;
