import BotTheme from "./bot-theme.json";
import imageIntroduction from "../constants/images/image_bot_introduction.svg";
import imageBotResult from "../constants/images/image_bot_result.svg";
import imageBotWarning from "../constants/images/image_bot_timeout.svg";
import imageBotError from "../constants/images/image_bot_error.svg";

export const botTheme = () => {
  BotTheme.questionnaire.intro_image_url = imageIntroduction;
  BotTheme.questionnaire.result_image_url = imageBotResult;
  BotTheme.questionnaire.warning_image_url = imageBotWarning;
  BotTheme.questionnaire.error_image_url = imageBotError;
  return BotTheme;
};
