var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import FooterComponent, { ButtonFooter, } from "../../../../components/common/Footer";
import Text from "../../../../components/common/Text";
import { closeWebView, getFieldLang, getLang, getQuestions, } from "../../../../utils/helper";
import { useApplication } from "../../../../App.context";
var Footer = function () {
    var onClickButton = useCallback(function () {
        closeWebView();
    }, []);
    var appTheme = useApplication().appTheme;
    var lang = getLang().toLowerCase();
    var data = getQuestions();
    return (_jsx(FooterComponent, __assign({ backgroundColor: "transparent" }, { children: _jsx(ButtonFooter, __assign({ onClick: onClickButton, backgroundColor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.default.button.background_color }, { children: _jsx(Text, __assign({ color: "white", type: "Bold", size: "16px", lineHeight: "24px" }, { children: (data === null || data === void 0 ? void 0 : data.dynamic_label.accept_th) && (data === null || data === void 0 ? void 0 : data.dynamic_label.accept_en)
                    ? getFieldLang(data === null || data === void 0 ? void 0 : data.dynamic_label, "accept", lang)
                    : lang === "en"
                        ? "OK"
                        : "ตกลง" })) })) })));
};
export default Footer;
