var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { FormControl, InputLabel } from "@mui/material";
import SelectMui from "@mui/material/Select";
var Select = function (props) {
    var value = props.value, onChange = props.onChange, children = props.children, _a = props.name, name = _a === void 0 ? "select-field" : _a, label = props.label, resetProp = __rest(props, ["value", "onChange", "children", "name", "label"]);
    var handleChange = useCallback(function (event) {
        var value = event.target.value;
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    }, [onChange]);
    return (_jsxs(FormControl, __assign({ component: "fieldset" }, { children: [_jsx(InputLabel, __assign({ id: "".concat(label, "-select-type") }, { children: label })), _jsx(SelectMui, __assign({ labelId: "".concat(label, "-select-type"), label: label, name: name, value: value }, resetProp, { onChange: handleChange }, { children: children }))] })));
};
export default Select;
