var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
export default function ImagePlaceholder(_a) {
    var bg = _a.bg, fg = _a.fg;
    return (_jsxs("svg", __assign({ width: "240", height: "180", viewBox: "0 0 240 180", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { width: "240", height: "180", rx: "8", fill: bg }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M133 77H107C105.343 77 104 78.3431 104 80V100C104 101.657 105.343 103 107 103H133C134.657 103 136 101.657 136 100V80C136 78.3431 134.657 77 133 77ZM133 79C133.552 79 134 79.4477 134 80V91.0954L130.228 87.5077C129.861 87.1296 129.27 87.1044 128.874 87.4321L128.792 87.5077L122.881 93.6035L116.97 87.5075L116.889 87.4319C116.492 87.1042 115.901 87.1294 115.535 87.5075L106 97.0204V80C106 79.4477 106.448 79 107 79H133ZM106 99.8448V100C106 100.552 106.448 101 107 101H133C133.552 101 134 100.552 134 100V93.8595L129.511 89.6401L124.274 95.0401L124.287 95.0537L123.847 95.4808L122.911 96.4461L122.881 96.4173L122.852 96.4459L121.921 95.4861L121.475 95.0539L121.488 95.0402L116.251 89.6401L106 99.845L106 99.8448ZM124.001 81C122.103 81 120.577 82.5738 120.577 84.5C120.577 86.4262 122.103 88 124.001 88C125.9 88 127.426 86.4262 127.426 84.5C127.426 82.5738 125.9 81 124.001 81ZM124.001 83C124.781 83 125.426 83.6648 125.426 84.5C125.426 85.3352 124.781 86 124.001 86C123.222 86 122.577 85.3352 122.577 84.5C122.577 83.6648 123.222 83 124.001 83Z", fill: fg })] })));
}
