import { jsx as _jsx } from "react/jsx-runtime";
import Content from "./Components/Content";
import { Container } from "./style";
import { useRouter } from "../../utils/helper";
import { useEffect } from "react";
var Introduction = function () {
    var _a;
    var state = ((_a = useRouter()) === null || _a === void 0 ? void 0 : _a.location).state;
    var _b = state || {}, title = _b.title, latestSubmissionDatetimeLabel = _b.latestSubmissionDatetimeLabel, latestSubmissionDatetime = _b.latestSubmissionDatetime, testResultLabel = _b.testResultLabel, testResult = _b.testResult, subTitle = _b.subTitle, retryButton = _b.retryButton, submissionLimit = _b.submissionLimit, totalSubmission = _b.totalSubmission, anableButton = _b.anableButton;
    useEffect(function () {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (_jsx(Container, { children: _jsx(Content, { title: title, latestSubmissionDatetimeLabel: latestSubmissionDatetimeLabel, latestSubmissionDatetime: latestSubmissionDatetime, testResultLabel: testResultLabel, testResult: testResult, subTitle: subTitle, retryButton: retryButton, submissionLimit: submissionLimit, totalSubmission: totalSubmission, anableButton: anableButton }) }));
};
export default Introduction;
