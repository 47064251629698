var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from "../../../../components/common/Text";
import FlexCard from "../../../../components/common/FlexCard";
import { SubContainer } from "../../style";
import { Container, EmptySpace, StyledImage, StyledLayoutText, StyledLayoutTextContent, StyledLayoutTextQuestion, StyledLayoutTextResult, } from "./style";
import { GRAY_5A6E7B, GRAY_F9FAFB, RED_FFF0F0, BLACK_000000, } from "../../../../constants/colors";
import ResultText from "../../../../components/common/ResultText";
import Footer from "../Footer";
import { getFieldLang, getLang, getQuestions, getResult, getToken, } from "../../../../utils/helper";
import { useEffect, useState } from "react";
import { useApplication } from "../../../../App.context";
import { useGetInquiryAnswer } from "../../../../services/success/success-query";
import ReactDOMServer from "react-dom/server";
import ImagePlaceholder from "../../../../components/common/Placeholder/image";
import SkeletonUI from "./SkeletonUI";
import { DangerousDiv } from "../../../Introduction/Components/Content/style";
var Content = function () {
    var appTheme = useApplication().appTheme;
    var _a = useState([]), successResult = _a[0], setSuccessResult = _a[1];
    var _b = useState(true), Loading = _b[0], setLoading = _b[1];
    var _c = useState(true), fallbackImage = _c[0], setFallbackImage = _c[1];
    var _d = useGetInquiryAnswer(), isLoading = _d.isLoading, isSuccess = _d.isSuccess, responseAnswer = _d.data, getInquiryAnswer = _d.mutate;
    var _e = useState(0), totalCorrect = _e[0], setTotalCorrect = _e[1];
    var token = getToken();
    var questionList = getQuestions();
    var answerResult = getResult();
    var lang = getLang().toLowerCase();
    var imagePlaceholder = ReactDOMServer.renderToString(_jsx(ImagePlaceholder, { bg: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.placeholder_color_1, fg: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.placeholder_color_2 }));
    useEffect(function () {
        if (isSuccess) {
            setLoading(false);
        }
    }, [isSuccess]);
    useEffect(function () {
        if (token && token.length > 0) {
            getInquiryAnswer({
                survey_subgroup_id: Number(questionList === null || questionList === void 0 ? void 0 : questionList.survey_subgroup_id) || null,
            });
        }
    }, [token, getInquiryAnswer]);
    useEffect(function () {
        if (responseAnswer) {
            updateResultAnswers(responseAnswer);
        }
    }, [responseAnswer]);
    var updateResultAnswers = function (answer) {
        var answerQuestion = answer.questions;
        var dataSuccessResult = [];
        var correctCount = 0;
        var result = answerResult;
        var resultSorted = __spreadArray([], result.answers, true).sort(function (a, b) { return a.qId - b.qId; });
        resultSorted.forEach(function (value, index) {
            var questionResult = answerQuestion.find(function (item) { return item.id === value.qId; });
            var currentQuestion = questionList.questions.find(function (item) { return item.id === value.qId; });
            if (questionResult && currentQuestion) {
                var optionResult = questionResult.options.find(function (item) { return item.id === value.optId; });
                var currentAnswer = currentQuestion.options.find(function (item) { return item.id === value.optId; });
                if (optionResult) {
                    dataSuccessResult.push({
                        question: getFieldLang(questionList.questions[index], "title", lang),
                        question_voiceover: getFieldLang(questionList.questions[index], "title_voiceover", lang),
                        answer: getFieldLang(currentAnswer, "label", lang),
                        answer_voiceover: getFieldLang(currentAnswer, "label_voiceover", lang),
                        correct: optionResult.is_correct,
                        result_description: getFieldLang(optionResult, "result_description", lang),
                        result_description_voiceover: getFieldLang(optionResult, "result_description_voiceover", lang),
                        correct_message: isAnswerCorrect(optionResult.is_correct, lang),
                    });
                    if (optionResult.is_correct) {
                        correctCount++;
                    }
                }
            }
        });
        setTotalCorrect(correctCount);
        setSuccessResult(dataSuccessResult);
    };
    var isAnswerCorrect = function (isCorrect, lang) {
        if (isCorrect) {
            switch (lang) {
                case "th":
                    return "ถูกต้อง";
                case "en":
                    return "Correct";
                default:
                    return "ถูกต้อง";
            }
        }
        else {
            switch (lang) {
                case "th":
                    return "ไม่ถูกต้อง";
                case "en":
                    return "Incorrect";
                default:
                    return "ถูกต้อง";
            }
        }
    };
    var renderCorrectAmount = function (totalCorrect, length, lang) {
        switch (lang) {
            case "th":
                return "\u0E16\u0E39\u0E01 ".concat(totalCorrect, " \u0E08\u0E32\u0E01 ").concat(length, " \u0E02\u0E49\u0E2D");
            case "en":
                return "".concat(totalCorrect, " correct out of ").concat(length);
            default:
                return "\u0E16\u0E39\u0E01 ".concat(totalCorrect, " \u0E08\u0E32\u0E01 ").concat(length, " \u0E02\u0E49\u0E2D");
        }
    };
    return (_jsx(Container, { children: Loading ? (_jsx(SkeletonUI, {})) : (_jsx(SubContainer, __assign({ backgroundColor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.default.background.color }, { children: _jsxs(FlexCard, __assign({ bgColor: GRAY_F9FAFB, topImage: _jsx(StyledImage, __assign({ fallbackImage: fallbackImage, role: "img", draggable: "false", type: "image/svg+xml", "aria-label": "Success Image", data: (appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.result_image_url)
                        ? appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.result_image_url
                        : "", onError: function (_a) {
                        var currentTarget = _a.currentTarget;
                        currentTarget.onerror = null;
                        currentTarget.data = "data:image/svg+xml,".concat(encodeURIComponent(imagePlaceholder));
                        setFallbackImage(false);
                    } }, { children: "Success Image" })) }, { children: [_jsxs(StyledLayoutText, __assign({ style: { gap: "0.5rem" } }, { children: [_jsx(Text, __assign({ type: "Bold", size: "20px", lineHeight: "30px", talk: getFieldLang(questionList.dynamic_label.result, "text1", lang), textAlign: "center" }, { children: getFieldLang(questionList.dynamic_label.result, "text1", lang) })), _jsx(Text, __assign({ type: "Bold", weight: 700, color: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.result.color, size: "24px", lineHeight: "35px", talk: renderCorrectAmount(totalCorrect, successResult.length, lang) }, { children: renderCorrectAmount(totalCorrect, successResult.length, lang) }))] })), successResult.map(function (item, index) { return (_jsx("div", { children: _jsxs(FlexCard, __assign({ marginY: 1, boxShadow: "0px 2px 4px 0px rgba(188, 204, 214, 0.40)" }, { children: [_jsx(StyledLayoutTextContent, { children: _jsx(StyledLayoutTextQuestion, { children: _jsx(Text, __assign({ type: "Bold", size: "16px", lineHeight: "24px", talk: "".concat(index + 1, ". ").concat(item.question_voiceover) }, { children: "".concat(index + 1, ". ").concat(item.question) })) }) }), _jsxs(StyledLayoutTextContent, { children: [_jsxs(StyledLayoutTextResult, { children: [_jsx(DangerousDiv, { style: {
                                                        fontFamily: "KrungthaiFast-Regular",
                                                        display: "flex",
                                                        textAlign: "left",
                                                        width: "240px",
                                                        whiteSpace: "nowrap",
                                                        fontWeight: 400,
                                                        color: GRAY_5A6E7B,
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                    }, dangerouslySetInnerHTML: {
                                                        __html: getFieldLang(questionList.dynamic_label.result, "submit_answer_text", lang),
                                                    } }), _jsx(DangerousDiv, { style: {
                                                        fontFamily: "KrungthaiFast-Regular",
                                                        fontWeight: 700,
                                                        color: GRAY_5A6E7B,
                                                        fontSize: "16px",
                                                        width: "100%",
                                                        lineHeight: "24px",
                                                        display: "flex",
                                                        textAlign: "right",
                                                        justifyContent: "flex-end",
                                                    }, dangerouslySetInnerHTML: {
                                                        __html: item.answer,
                                                    } })] }), _jsxs(StyledLayoutTextResult, { children: [_jsx(Text, __assign({ weight: 400, color: GRAY_5A6E7B, size: "16px", lineHeight: "24px" }, { children: getFieldLang(questionList.dynamic_label.result, "answer_text", lang) })), _jsx(ResultText, { isCorrect: item.correct, text: item.correct_message })] }), !item.correct && (_jsx(FlexCard, __assign({ bgColor: RED_FFF0F0 }, { children: _jsx(Text, __assign({ weight: 400, color: BLACK_000000, size: "14px", lineHeight: "21px", textAlign: "left", talk: item.result_description_voiceover }, { children: item.result_description })) })))] })] })) }, index)); }), _jsx("div", __assign({ style: { marginTop: "32px" } }, { children: _jsx(Footer, { isLoading: Loading }) })), _jsx(EmptySpace, {})] })) }))) }));
};
export default Content;
