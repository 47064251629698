var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  background-color: white;\n  border-radius: 16px 16px 0px 0px;\n  flex-direction: column;\n  margin-top: 24px;\n  height: 100%;\n  overflow: auto;\n"], ["\n  display: flex;\n  background-color: white;\n  border-radius: 16px 16px 0px 0px;\n  flex-direction: column;\n  margin-top: 24px;\n  height: 100%;\n  overflow: auto;\n"])));
export var ContainerBackground = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  /* overflow: auto; */\n  height: 100%;\n  min-height: 85vh;\n  /* margin-bottom: 88px; */\n  /* align-items: center; */\n"], ["\n  display: flex;\n  flex-direction: column;\n  /* overflow: auto; */\n  height: 100%;\n  min-height: 85vh;\n  /* margin-bottom: 88px; */\n  /* align-items: center; */\n"])));
export var ContainerCard = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: auto;\n  width: 100%;\n  padding-top: 8px;\n  overflow: auto;\n  ::-webkit-scrollbar {\n    display: none;\n    width: 0 !important;\n  }\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* IE 10+ */\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: auto;\n  width: 100%;\n  padding-top: 8px;\n  overflow: auto;\n  ::-webkit-scrollbar {\n    display: none;\n    width: 0 !important;\n  }\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* IE 10+ */\n"])));
export var ContainerSkeletonCard = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 8px 16px 8px 16px;\n  display: flex;\n  flex-direction: column;\n"], ["\n  padding: 8px 16px 8px 16px;\n  display: flex;\n  flex-direction: column;\n"])));
export var ContainerSkeleton = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  margin: 16px 0 16px 0;\n"], ["\n  display: flex;\n  margin: 16px 0 16px 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
