var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from "react";
import { ButtonFooter } from "../../../../components/common/Footer";
import Text from "../../../../components/common/Text";
import { clearSession, closeWebView, getFieldLang, getLang, getQuestions } from "../../../../utils/helper";
import Skeleton from "@mui/material/Skeleton";
import { useApplication } from "../../../../App.context";
var Footer = function (_a) {
    var isLoading = _a.isLoading;
    var questionList = getQuestions();
    var lang = getLang().toLowerCase();
    var onClickButton = useCallback(function () {
        clearSession();
        closeWebView();
    }, []);
    // const isSkeleton = false;
    var appTheme = useApplication().appTheme;
    return (_jsx(_Fragment, { children: isLoading ? (_jsx(Skeleton, { variant: "rounded", animation: "wave", width: "100%", height: 48, sx: {
                maxWidth: "358px",
                bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                borderRadius: "0.75rem",
                marginLeft: "auto",
                marginRight: "auto",
            } })) : (_jsx(ButtonFooter, __assign({ onClick: onClickButton, talk: getFieldLang(questionList.dynamic_label.result, "complete_text", lang), backgroundColor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.default.button.background_color }, { children: _jsx(Text, __assign({ color: "white", type: "Bold", size: "16px", lineHeight: "24px" }, { children: getFieldLang(questionList.dynamic_label.result, "complete_text", lang) })) }))) }));
};
export default Footer;
