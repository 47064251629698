var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from "styled-components";
var loadingAnimation = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%,\n  100% {\n    left: 0px;\n    width: 10.67px;\n    height: 10.67px;\n    z-index: 0;\n  }\n\n  25% {\n    height: 10.67px;\n    width: 10.67px;\n    z-index: 1;\n    left: 10px;\n  }\n\n  50% {\n    width: 10.67px;\n    height: 10.67px;\n    left: 24px;\n  }\n\n  75% {\n    width: 10.67px;\n    height: 10.67px;\n    left: 13px;\n  }\n"], ["\n  0%,\n  100% {\n    left: 0px;\n    width: 10.67px;\n    height: 10.67px;\n    z-index: 0;\n  }\n\n  25% {\n    height: 10.67px;\n    width: 10.67px;\n    z-index: 1;\n    left: 10px;\n  }\n\n  50% {\n    width: 10.67px;\n    height: 10.67px;\n    left: 24px;\n  }\n\n  75% {\n    width: 10.67px;\n    height: 10.67px;\n    left: 13px;\n  }\n"])));
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  height: 100vh;\n"], ["\n  position: relative;\n  height: 100vh;\n"])));
export var HeaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n"], ["\n  position: absolute;\n"])));
export var ContentContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n"])));
export var DotContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 40px !important;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 40px !important;\n"])));
export var DotLoading = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 40px;\n  position: relative;\n\n  &::before,\n  &::after {\n    position: absolute;\n    content: \"\";\n    background: #5a6e7b;\n    width: 10.67px;\n    height: 10.67px;\n    border-radius: 50%;\n    mix-blend-mode: multiply;\n    animation: ", " 1s linear infinite;\n  }\n\n  &::after {\n    background: #bcccd6;\n    animation-delay: 0.5s;\n  }\n"], ["\n  width: 40px;\n  position: relative;\n\n  &::before,\n  &::after {\n    position: absolute;\n    content: \"\";\n    background: #5a6e7b;\n    width: 10.67px;\n    height: 10.67px;\n    border-radius: 50%;\n    mix-blend-mode: multiply;\n    animation: ", " 1s linear infinite;\n  }\n\n  &::after {\n    background: #bcccd6;\n    animation-delay: 0.5s;\n  }\n"])), loadingAnimation);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
