var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from "../../../../components/common/Text";
import { GRAY_5A6E7B } from "../../../../constants/colors";
import QuestionList from "./Components/QuestionList";
import { Container, ContainerTextDescription, ContainerTitle } from "./styled";
import Skeleton from "@mui/material/Skeleton";
import { getFieldLang, getLang, getQuestions } from "../../../../utils/helper";
import { useApplication } from "../../../../App.context";
var Content = function (_a) {
    var isLoading = _a.isLoading;
    var appTheme = useApplication().appTheme;
    var data = getQuestions();
    var skeletonQuestions = [];
    var lang = getLang().toLowerCase();
    for (var index = 0; index < 3; index++) {
        skeletonQuestions.push(_jsx(Skeleton, { variant: "rounded", width: index === 2 ? "50%" : "100%", animation: "wave", sx: {
                height: "16px",
                bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                marginBottom: "8px",
            }, style: {
                borderRadius: "6px",
            } }));
    }
    return (_jsxs(Container, { children: [_jsx(ContainerTitle, { children: isLoading ? (skeletonQuestions) : (_jsxs(ContainerTitle, { children: [_jsx(Text, __assign({ type: "Bold", size: "16px", lineHeight: "24px", talk: getFieldLang(data, "title_voiceover", lang) }, { children: getFieldLang(data, "title", lang) })), _jsx(ContainerTextDescription, { children: _jsx(Text, __assign({ weight: 400, color: GRAY_5A6E7B, size: "16px", lineHeight: "24px" }, { children: getFieldLang(data, "description", lang) })) })] })) }), _jsx(QuestionList, { isLoading: isLoading }, "questionList")] }));
};
export default Content;
