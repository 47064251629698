var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import FooterComponent, { ButtonFooter, } from "../../../../components/common/Footer";
import Text from "../../../../components/common/Text";
import { closeWebView } from "../../../../utils/helper";
import { useApplication } from "../../../../App.context";
var Footer = function () {
    var onClickButton = useCallback(function () {
        closeWebView();
    }, []);
    var appTheme = useApplication().appTheme;
    return (_jsx(FooterComponent, { children: _jsx(ButtonFooter, __assign({ onClick: onClickButton, talk: "\u0E01\u0E25\u0E31\u0E1A\u0E2B\u0E19\u0E49\u0E32\u0E2B\u0E25\u0E31\u0E01", backgroundColor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.default.button.background_color }, { children: _jsx(Text, __assign({ color: "white", type: "Bold", size: "16px", lineHeight: "24px" }, { children: "\u0E01\u0E25\u0E31\u0E1A\u0E2B\u0E19\u0E49\u0E32\u0E2B\u0E25\u0E31\u0E01" })) })) }));
};
export default Footer;
