var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, ContentContainer, DotContainer, DotLoading, HeaderContainer, } from "./style";
import Text from "../Text";
import { useRouter } from "../../../utils/helper";
import HeaderSpace from "../../HeaderSpace";
import { useEffect, useState } from "react";
var Loading = function () {
    var query = useRouter().query;
    var lang = query.lang;
    var _a = useState("TH"), language = _a[0], setLanguage = _a[1];
    useEffect(function () {
        if (lang) {
            setLanguage(lang);
        }
    }, [lang]);
    return (_jsxs(Container, { children: [_jsx(HeaderContainer, { children: _jsx(HeaderSpace, { LoadingColor: "#BCCCD6" }) }), _jsxs(ContentContainer, { children: [_jsx(DotContainer, { children: _jsx(DotLoading, {}) }), _jsx(Text, __assign({ color: "#5A6E7B" }, { children: language
                            ? language === "EN"
                                ? "Loading"
                                : "กำลังโหลด"
                            : "กำลังโหลด" }))] })] }));
};
export default Loading;
