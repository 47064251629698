var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMutation, useQueryClient } from "react-query";
import { genTraceId, setToken, setQuestions, setLatestSubmissionInfo, setParamURL, setResponse, } from "../../utils/helper";
import { api } from "../../utils/api";
import { useApplication } from "../../App.context";
export var QUESTIONAIRE_INQUIRY = "survey/questionnaire-inquiry/questions";
export var SETTING = "setting";
export var GENERATE_TOKEN = "token";
export var GENERATE_TOKEN_IBANK = "partner/auth/token/generate";
export var GENERATE_TOKEN_PT = "pt/auth/token/generate";
export var GENERATE_TOKEN_NEXT = "next/auth/token/generate";
var validateSaveQueryParams = function (params) {
    var authorization_code = params.authorization_code, ref_id = params.ref_id, channel = params.channel, lang = params.lang, survey_name = params.survey_name;
    if (!authorization_code || (authorization_code === null || authorization_code === void 0 ? void 0 : authorization_code.length) === 0) {
        return Promise.reject(null);
    }
    if (!survey_name || (survey_name === null || survey_name === void 0 ? void 0 : survey_name.length) === 0) {
        return Promise.reject(null);
    }
    if (!channel ||
        (channel === null || channel === void 0 ? void 0 : channel.length) === 0 ||
        (channel !== "PAOTANG" && channel !== "NEXT" && channel !== "IBANK")) {
        return Promise.reject(null);
    }
    if (channel === "IBANK" && ref_id === "") {
        return Promise.reject(null);
    }
    if (!lang || (lang === null || lang === void 0 ? void 0 : lang.length) === 0 || (lang !== "TH" && lang !== "EN")) {
        return Promise.reject(null);
    }
};
export var useSaveQueryParams = function () {
    var p;
    var queryClient = useQueryClient();
    return useMutation(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setParamURL(params);
                    return [4 /*yield*/, validateSaveQueryParams(params)];
                case 1:
                    _a.sent();
                    p = params;
                    return [2 /*return*/];
            }
        });
    }); }, {
        onSuccess: function () {
            var channel = p.channel, lang = p.lang, survey_name = p.survey_name, survey_group_id = p.survey_group_id, survey_subgroup_id = p.survey_subgroup_id;
            queryClient.setQueryData([SETTING], {
                channel: channel,
                lang: lang,
                survey_name: survey_name,
                survey_group_id: survey_group_id,
                survey_subgroup_id: survey_subgroup_id,
            });
        },
    });
};
export var useGetQueryParams = function () {
    var queryClient = useQueryClient();
    var data = queryClient.getQueryData([SETTING]);
    if (!data) {
        data = {
            channel: "",
            lang: "",
            survey_name: "",
            survey_group_id: null,
            survey_subgroup_id: null,
        };
    }
    return data;
};
export var useGenerateToken = function () {
    return useMutation(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var channel, path, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    genTraceId();
                    channel = params.channel;
                    path = channel === "NEXT"
                        ? GENERATE_TOKEN_NEXT
                        : channel === "PAOTANG"
                            ? GENERATE_TOKEN_PT
                            : GENERATE_TOKEN_IBANK;
                    if (channel === "NEXT" || channel === "PAOTANG") {
                        delete params.ref_id;
                    }
                    if (channel !== "NEXT") {
                        delete params.survey_name;
                    }
                    return [4 /*yield*/, api.gt.post(path, params)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.data];
            }
        });
    }); }, {
        onSuccess: function (data) {
            setToken(data.token);
        },
    });
};
var getQuestionList = function (url) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, api.public.get(url)];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); };
export var getThemeUrl = function (url) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, api.public.get(url)];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); };
export var useGetInqueryQuestion = function () {
    var setAppTheme = useApplication().setAppTheme;
    return useMutation(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var path, data, response, resultQuestionList, appTheme;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    path = QUESTIONAIRE_INQUIRY;
                    return [4 /*yield*/, api.gt.post(path, params)];
                case 1:
                    data = (_a.sent()).data;
                    response = data === null || data === void 0 ? void 0 : data.data;
                    setResponse(response);
                    if (data.code === 0 && response.latest_submission_info) {
                        setLatestSubmissionInfo(response.latest_submission_info);
                    }
                    if (!(data.code === 0 && response.question_url)) return [3 /*break*/, 4];
                    return [4 /*yield*/, getQuestionList(response.question_url)];
                case 2:
                    resultQuestionList = _a.sent();
                    setQuestions(resultQuestionList);
                    return [4 /*yield*/, getThemeUrl(response.theme_config_url)];
                case 3:
                    appTheme = _a.sent();
                    if (appTheme) {
                        setAppTheme(appTheme);
                    }
                    return [2 /*return*/, resultQuestionList];
                case 4: throw new Error("error");
            }
        });
    }); });
};
