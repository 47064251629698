var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from "@mui/material";
import QuestionCard from "../../../../../../components/QuestionCard";
import { Container, ContainerBackground, ContainerCard, ContainerSkeleton, ContainerSkeletonCard, } from "./styled";
import Skeleton from "@mui/material/Skeleton";
import { getQuestions } from "../../../../../../utils/helper";
import { useApplication } from "../../../../../../App.context";
var QuestionList = function (_a) {
    var _b;
    var isLoading = _a.isLoading;
    var appTheme = useApplication().appTheme;
    var data = getQuestions();
    var skeletonQuestions = [];
    for (var index = 0; index < 3; index++) {
        skeletonQuestions.push(_jsxs(ContainerSkeletonCard, { children: [_jsx(Skeleton, { variant: "rounded", width: "100%", animation: "wave", sx: {
                        height: "16px",
                        bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                        maxWidth: "300px",
                    }, style: {
                        borderRadius: "6px",
                    } }), _jsxs(ContainerSkeleton, { children: [_jsx(Skeleton, { variant: "rounded", animation: "wave", width: "100%", height: 16, sx: {
                                maxWidth: "64px",
                                bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                                marginRight: "1rem",
                                borderRadius: "6px",
                            } }), _jsx(Skeleton, { variant: "rounded", animation: "wave", width: "100%", height: 16, sx: {
                                maxWidth: "64px",
                                bgcolor: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.skeleton_color,
                                borderRadius: "6px",
                            } })] }), _jsx(Divider, { variant: "middle", sx: { borderColor: "#5A6E7B1A", margin: 0, height: "1px" }, "aria-hidden": "true" })] }));
    }
    return (_jsx(Container, { children: _jsx(ContainerBackground, { children: _jsx(ContainerCard, { children: isLoading
                    ? skeletonQuestions
                    : (_b = data === null || data === void 0 ? void 0 : data.questions) === null || _b === void 0 ? void 0 : _b.map(function (x, index) {
                        var _a;
                        return (_jsx(QuestionCard, __assign({}, x, { divider: index !== ((_a = data === null || data === void 0 ? void 0 : data.questions) === null || _a === void 0 ? void 0 : _a.length) - 1, index: index + 1 }), "".concat(index, "-").concat(x.id)));
                    }) }) }) }));
};
export default QuestionList;
