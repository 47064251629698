import IBankTheme from "./ibank-theme.json";
import imageIntroduction from "../constants/images/image_bot_introduction.svg";
import imageResult from "../constants/images/image_bot_result.svg";
import imageError from "../constants/images/image_bot_error_ibank.svg";
import imageWarning from "../constants/images/image_bot_timeout_ibank.svg";

export const iBankTheme = () => {
  IBankTheme.questionnaire.intro_image_url = imageIntroduction;
  IBankTheme.questionnaire.result_image_url = imageResult;
  IBankTheme.questionnaire.error_image_url = imageError;
  IBankTheme.questionnaire.warning_image_url = imageWarning;
  return IBankTheme;
};
