var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from "styled-components";
import { Box } from "@mui/material";
import { WHITE_FFFFFF } from "../../../constants/colors";
export var StyledCard = styled(Box)(function (_a) {
    var topImage = _a.topImage, bgColor = _a.bgColor, fullHeight = _a.fullHeight, _b = _a.marginX, marginX = _b === void 0 ? 0 : _b, _c = _a.marginY, marginY = _c === void 0 ? 0 : _c, _d = _a.paddingX, paddingX = _d === void 0 ? 16 : _d, _e = _a.paddingY, paddingY = _e === void 0 ? 16 : _e, paddingTop = _a.paddingTop;
    return (__assign({ display: "flex", flexDirection: "column", backgroundColor: bgColor !== null && bgColor !== void 0 ? bgColor : "".concat(WHITE_FFFFFF), position: "relative", boxSizing: "border-box", zIndex: 1, top: topImage ? -30 : 0, borderRadius: 16, paddingTop: topImage ? (paddingTop || 40) : paddingY, paddingBottom: paddingY, paddingLeft: paddingX, paddingRight: paddingX, marginLeft: marginX, marginRight: marginX, marginTop: marginY, marginBottom: marginY }, (fullHeight && { height: "100vh" })));
});
export var StyledImageTop = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  z-index: 2;\n"], ["\n  position: relative;\n  z-index: 2;\n"])));
var templateObject_1;
