var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import Text from "../../../../components/common/Text";
import { GRAY_5A6E7B, GRAY_BCCCD6 } from "../../../../constants/colors";
import { useGetError } from "../../../../services/error/error-query";
import { ContainerDetail, ContainerErrorCode, ContainerTitle, StyledImage, } from "./style";
import ReactDOMServer from "react-dom/server";
import ImagePlaceholder from "../../../../components/common/Placeholder/image";
import { useApplication } from "../../../../App.context";
import { getLang } from "../../../../utils/helper";
var Content = function () {
    var appTheme = useApplication().appTheme;
    var dataError = useGetError().data;
    var lang = getLang().toLowerCase();
    var error = useMemo(function () {
        var error = {};
        if (dataError) {
            var status = dataError.status, data = dataError.data;
            console.log("dataError", dataError);
            var _a = data || {}, exp = _a.exp, code = _a.code, message = _a.message;
            if (lang === "en") {
                if (status && status === 502) {
                    error = {
                        title: "Unable to proceed to the test at the moment.",
                        detail: "Please try again later.",
                        isErrorCode: true,
                        errorCode: "9999999",
                        errorCodeVoice: "nine nine nine nine nine nine nine",
                        errorMessage: "Service Timeout",
                        errorImage: appTheme.questionnaire.error_image_url,
                    };
                }
                else if (exp && exp === "token expired") {
                    error = {
                        title: "Session Timeout",
                        detail: "You can try retaking the test.",
                        isErrorCode: false,
                        errorImage: appTheme.questionnaire.warning_image_url,
                    };
                }
                else {
                    error = {
                        title: "Unable to proceed to the test at the moment.",
                        detail: "Please try again later.",
                        isErrorCode: true,
                        errorCode: code || "9999999",
                        errorCodeVoice: "".concat(code) || "nine nine nine nine nine nine nine",
                        errorMessage: message || "Unknown Error",
                        errorImage: appTheme.questionnaire.error_image_url,
                    };
                }
            }
            else {
                if (status && status === 502) {
                    error = {
                        title: "ไม่สามารถทำแบบทดสอบได้ในขณะนี้",
                        detail: "กรุณาลองใหม่อีกครั้งภายหลัง",
                        isErrorCode: true,
                        errorCode: "9999999",
                        errorCodeVoice: "nine nine nine nine nine nine nine",
                        errorMessage: "Service Timeout",
                        errorImage: appTheme.questionnaire.error_image_url,
                    };
                }
                else if (exp && exp === "token expired") {
                    error = {
                        title: "หมดเวลาทำแบบทดสอบ",
                        detail: "คุณสามารถทำใหม่ได้อีกครั้ง",
                        isErrorCode: false,
                        errorImage: appTheme.questionnaire.warning_image_url,
                    };
                }
                else {
                    error = {
                        title: "ไม่สามารถทำแบบทดสอบได้ในขณะนี้",
                        detail: "กรุณาลองใหม่อีกครั้งภายหลัง",
                        isErrorCode: true,
                        errorCode: code || "9999999",
                        errorCodeVoice: "nine nine nine nine nine nine nine",
                        errorMessage: message || "Unknown Error",
                        errorImage: appTheme.questionnaire.error_image_url,
                    };
                }
            }
        }
        return error;
    }, [dataError]);
    var imagePlaceholder = ReactDOMServer.renderToString(_jsx(ImagePlaceholder, { bg: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.placeholder_color_1, fg: appTheme === null || appTheme === void 0 ? void 0 : appTheme.questionnaire.placeholder_color_2 }));
    return (_jsxs(_Fragment, { children: [_jsx(StyledImage, { src: error.errorImage ? error.errorImage : "", onError: function (_a) {
                    var currentTarget = _a.currentTarget;
                    currentTarget.onerror = null;
                    currentTarget.src = "data:image/svg+xml,".concat(encodeURIComponent(imagePlaceholder));
                }, alt: "".concat(error.title, " ").concat(error.detail) }), _jsx(ContainerTitle, { children: _jsx(Text, __assign({ type: "Bold", size: "20px", lineHeight: "30px" }, { children: error === null || error === void 0 ? void 0 : error.title })) }), _jsx(ContainerDetail, { children: _jsx(Text, __assign({ weight: 400, size: "16px", lineHeight: "24px", color: GRAY_5A6E7B }, { children: error === null || error === void 0 ? void 0 : error.detail })) }), (error === null || error === void 0 ? void 0 : error.isErrorCode) && (_jsx(ContainerErrorCode, { children: _jsxs(Text, __assign({ weight: 400, size: "12px", lineHeight: "18px", color: GRAY_BCCCD6, "aria-label": error === null || error === void 0 ? void 0 : error.errorCodeVoice }, { children: ["Error Code: ", error === null || error === void 0 ? void 0 : error.errorCode, " - ", error === null || error === void 0 ? void 0 : error.errorMessage] })) }))] }));
};
export default Content;
