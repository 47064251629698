var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect } from "react";
import { Form } from "react-final-form";
import { difference } from "lodash";
import Footer from "./Components/Footer";
import Content from "./Components/Content";
import { StyleForm } from "./style";
import { useSubmitQuestionBOT } from "../../services/bot/bot-query";
import { useGetQueryParams } from "../../services/home/home-query";
import { useRouter, setResult, getQuestions, getParamURL, } from "../../utils/helper";
import { useApplication } from "../../App.context";
var Survey = function () {
    var query = useRouter().query;
    var dataQueryParams = useGetQueryParams();
    var data = getQuestions();
    var _a = getParamURL(), channel = _a.channel, lang = _a.lang;
    var isLoading = useApplication().isLoading;
    var submitQuestionBOT = useSubmitQuestionBOT().mutate;
    var onSubmit = useCallback(function (value) {
        var newValue = Object.entries(value.questions).map(function (_a) {
            var key = _a[0], value = _a[1];
            var surveyQuestionId = Number(key.replace("question", ""));
            var optionId = JSON.parse(value).id;
            return { qId: surveyQuestionId, optId: optionId };
        });
        var params = {
            channel: channel,
            lang: lang,
            answers: newValue,
            tempChannel: dataQueryParams === null || dataQueryParams === void 0 ? void 0 : dataQueryParams.channel,
        };
        setResult(params);
        submitQuestionBOT(params);
    }, [query, data, dataQueryParams, submitQuestionBOT]);
    var validateForm = useCallback(function (value) {
        var questions = value.questions;
        var errors = { questions: {} };
        var newQuestion = (questions && Object.entries(questions)) || [];
        if (newQuestion.length !== (data === null || data === void 0 ? void 0 : data.questions.length)) {
            var x = newQuestion.map(function (_a) {
                var key = _a[0];
                return key;
            });
            var errorNumber = difference(data === null || data === void 0 ? void 0 : data.questions.map(function (q) { return "question".concat(q.id); }), x);
            for (var _i = 0, errorNumber_1 = errorNumber; _i < errorNumber_1.length; _i++) {
                var n = errorNumber_1[_i];
                errors["questions"][n] = "";
            }
        }
        return errors;
    }, [data === null || data === void 0 ? void 0 : data.questions]);
    useEffect(function () {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (_jsx(Form, __assign({ onSubmit: onSubmit, validate: validateForm }, { children: function (_a) {
            var handleSubmit = _a.handleSubmit;
            return (_jsxs(StyleForm, __assign({ onSubmit: handleSubmit }, { children: [_jsx(Content, { isLoading: isLoading }), _jsx(Footer, { onClickButton: handleSubmit, isSkeleton: isLoading })] })));
        } })));
};
export default Survey;
