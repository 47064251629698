import { useQuery } from "react-query";
export var ERROR = "error";
export var useGetError = function () {
    return useQuery([ERROR], function () {
        return {
            status: 500,
            data: {
                code: 999999,
                message: "Unknown Error",
                // exp: "token expired",
            },
        };
    });
};
