var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from "@mui/material";
var Text = function (props) {
    var children = props.children, className = props.className, _a = props.color, color = _a === void 0 ? "black" : _a, _b = props.lineHeight, lineHeight = _b === void 0 ? "24px" : _b, _c = props.size, size = _c === void 0 ? "16px" : _c, _d = props.type, type = _d === void 0 ? "Regular" : _d, _e = props.talk, talk = _e === void 0 ? "" : _e, textAlign = props.textAlign, _f = props.width, width = _f === void 0 ? "auto" : _f, _g = props.display, display = _g === void 0 ? "unset" : _g;
    return (_jsx(Typography, __assign({ sx: { display: display }, width: width, className: className, color: color, lineHeight: lineHeight, fontSize: size, fontFamily: ["KrungthaiFast-".concat(type)].join(","), "aria-label": talk || children, textAlign: textAlign }, { children: children })));
};
export default Text;
