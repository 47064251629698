var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  position: relative;\n  box-sizing: border-box;\n  width: 100vw;\n  z-index: 10;\n  display: flex;\n  padding-top: 44px;\n"], ["\n  background-color: ", ";\n  position: relative;\n  box-sizing: border-box;\n  width: 100vw;\n  z-index: 10;\n  display: flex;\n  padding-top: 44px;\n"])), function (_a) {
    var backgroundColor = _a.backgroundColor;
    return backgroundColor ? backgroundColor : "rgba(0, 0, 0, 0)";
});
export var ContainerContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  text-align: center;\n  width: 100%;\n  height: 30px;\n  justify-content: center;\n  padding-top: 8px;\n  padding-bottom: 7px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  text-align: center;\n  width: 100%;\n  height: 30px;\n  justify-content: center;\n  padding-top: 8px;\n  padding-bottom: 7px;\n"])));
export var ContainerIcon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-right: 18px;\n  padding-top: 5px;\n  position: absolute;\n  right: 0;\n\n  & .MuiIconButton-root {\n    width: 20px;\n    height: 20px;\n  }\n"], ["\n  padding-right: 18px;\n  padding-top: 5px;\n  position: absolute;\n  right: 0;\n\n  & .MuiIconButton-root {\n    width: 20px;\n    height: 20px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
